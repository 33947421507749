
   
<div class="animationLoader" *ngIf="isAction">
    <span><img src="../../../assets/rocket-loader.svg" /></span>
    
</div> 
<div class="SuggestionPart" *ngIf="!!searchTopicList.topic">
        
    
    <div class="aiSuggestionArea helpMeWrite">
    <h4 class="pt20">Why is <span>{{searchTopicList.topic}}</span> trending?</h4>
    
    <div class="dailySearchBox">
        <p class="suggesionArea">{{searchTopicList.reason}}</p>  
        <p class="writeAbout"  (click)="writeAboutTopic(searchTopicList.reason)"><img src="../../assets/write_topic.png" alt=""/>Write About This Topic</p>
        <h4>Idea about this topic</h4>
        <ul>
            <li *ngFor="let list of searchTopicList.ideas">
                {{list}}
            </li>
        </ul>
        <h4>Similar Tags</h4>
        <p class="keywordSearch" (click)="openRelatedArticle(searchTopicList?.similar_searches)">click here</p>
        <h5>Related News</h5>
        <div class="listRecommended">
            <ul>
                <li *ngFor="let item of searchTopicList.results;">
                    <div class="thumbImage">
                        <img [src]="item?.img" alt="" />
                    </div>
                    <div class="listDetail" (click)="openRelatedArticle(item.url)">
                        <h6>{{item?.title}}</h6>
                        <p>{{item?.snippet}}</p>
                        <!-- <div class="agencyTime"><span class="agency">India Today</span><span class="time">7g ago</span></div> -->
                    </div>
                </li>
               
            </ul>
        </div>

    </div>
    
</div>
</div> 