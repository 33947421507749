export class UserTracker{
   
        appId:string="1";
        activity:string= "login";
        user!: User
    
}
export interface User{
     
        userId: string,
        name: string,
        email: string
    }