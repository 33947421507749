<div class="aiContainer">
    <header>
        <h2>Ask to Zeeni</h2>
        <div class="pageTab">
            <ul>
                <li (click)="clickTabPage(1)" class="active">Content Creation</li>
                <li (click)="clickTabPage(2)" *ngIf="isHelpMeWrite">Help me write</li>
            </ul>
        </div>
    </header>

    <div class="workArea pad20">
    <div class="flex gap20 relative">
        <div class="bodyContainer pl20 pr20 pb20 pt0">
            <div class="tabs">
                <div class="tab" (click)="clickTab(0)">Create Content</div>
                <div class="tab active">Create Article</div>
            </div>
            <div class="zeeniInputBox">
            <div class="contentLeftBlock  pt20">
            <ng-container class="tags" *ngIf="articleCreationDomainConfig?.isTags">
                <!-- <h6 class="pt20">Tags</h6> -->
                <div class="tagContainer">
                <tags-creation (sendTags)="receivedTags($event)"></tags-creation>
            </div>
                  
               </ng-container>
              <ng-container class="realted-search" *ngIf="articleCreationDomainConfig?.isRelatedSearch">
               <div class="relative">
                   <div class="exporeTopic">   
                           <input type="text" #text placeholder="Related Search..." (keyup.enter)="searchTopic(text.value)" class="textBox" />
                           <span class="searchIcon" (click)="searchTopic(text.value)">
                               <img src="../../../assets/seachIcon.png" alt=" " />
                           </span>
                   </div>
                   <!-- <span>{{similarSearchUrl}}</span> -->
               </div>
       </ng-container>
     
                   
        <div class="outputContainer select" *ngIf="!!langList && langList.length>0">
            <select  (change)="changeOutputLang($event)" [(ngModel)]="defaultOutputLang">
                <option value ="" disabled="true">Choose Output language</option>
                <option [value]="lang" *ngFor="let lang of langList">{{lang}}</option>
            </select>
        </div>
       
       
            <div class="outputContainer select" *ngIf="!!articleResWrdLimit && articleResWrdLimit.length>0">
                <select  (change)="changeArticleWordLimit($event)" [(ngModel)]="articleDefaultLen">
                    <option value ="" disabled="true">Choose Article Length</option>
                    <option [value]="wordLimit" *ngFor="let wordLimit of articleResWrdLimit">{{wordLimit}}</option>
                </select>
            </div>
    
   

    </div>
            <div class="relative">
                
                               
            <h6 class="pt20">Enter your Text</h6><span class="wordCount pt20">Word limit <em>{{content?content.trim().split(' ').length:0}}</em>/{{wordLimit}}</span>
        </div>
                
                <div class="inputTextArea">
                   <div class="textInputContent" contenteditable="false">
                    <textarea   [(ngModel)]="content" [ngClass]="{filled: content}" placeholder="Enter Your Text..." (keyup)="trimContent(content)" >

                    </textarea>
                       <!-- <ckeditor [editor]="Editor" [(ngModel)]="content" name="body"></ckeditor> -->
                   </div>
                 
                 </div>
                
                   <div class="bottomOptions pt10">
                   <div class="select" *ngIf="!!toneList && toneList.length>0">
                   <select  (change)="changeTone($event)" [(ngModel)]="defaultTone">
                    <option value =""  disabled="true">Choose tone</option>
                    <option [value]="tone" *ngFor="let tone of toneList">{{tone}}</option>
                </select>
            </div>
            <div class="select">
                <select (change)="changeContentType($event)" [(ngModel)]="defaultContentType">
                 <option value =""  disabled="true">Choose Content Type</option>
                 <option [value]="contentType" *ngFor="let contentType of contentTypeList">{{contentType}}</option>
             </select>
         </div>
                   
                </div>
                <p class="errorMsg" *ngIf="content?.trim().split(' ').length>wordLimit">Your word limit has been exceeded.</p>
            </div>
                <div class="leftPanalBtnBlock pt10">
                    <div class="roboSuggestion">
                        <label [ngClass]="{hoverDisabled: !content || content?.trim().split(' ').length>wordLimit || content?.trim().length<1 || wizardState.length>1}"> 
                            <button (click)="generateWizard('Article')" class="btnArticle" [disabled]="content?.trim().split(' ').length>wordLimit || content?.trim().length<1 || wizardState.length>1"> Create Article </button>
                           
                        </label>
                        </div>
                </div>
        </div>
        <div class="SuggestionPart articlePart">
            <div class="animationLoader" *ngIf="!isAction && !headline">
                    <img src="../../assets/image_3.svg" alt="" />
            </div>
            <div class="animationLoader" *ngIf="isAction">
                <span><img src="../../assets/rocket-loader.svg" height="262px"/></span>
                
            </div> 
          
             <!-- Article Html -->
             <ng-container *ngIf="headline && !isCompleted">
            <div class="aiSuggestionArea" *ngIf="!isCompleted">
            
            <h6 class="pt20">Content Created for {{headline?headline:'Article'}}</h6>
        
            <!-- <h6 *ngIf="!valueHeading" class="pt20">Recommended by AI Assistant</h6> -->
            
            <div class="reacommendedArea">
                
              <ng-container *ngIf="isArr else elseBlock">
                <ng-container *ngFor="let headline of articleSuggestedData;let i = index;">
                    <div class="blockArea whiteBg headline">
                    <p>
                        <label>
                            <input type="radio"  name="radio" id ="radio{{i}}" [(ngModel)]="selectedHeadline" value="{{headline}}" (click)="setSelctedHeadline(headline,i)">
                          <span contenteditable="true" id ="headline{{i}}" >{{headline}}</span>
                          <span class="copySelction"><img src="../../assets/copy_icon.png" alt="Copy" (click)="copiedHeadline('headline',i)" /></span> 
                        </label>
                      </p>
                    </div>
                </ng-container>
              </ng-container>
              <ng-template #elseBlock>
                <div class="blockArea whiteBg articleTextArea">
                <textarea class ="inputTextArea" type="text" contenteditable="true" id="articleSuggested">{{articleSuggestedData}}</textarea>
                    <span  *ngIf="!!articleSuggestedData" class="copySelction"><img src="../../assets/copy_icon.png" alt="Copy" (click)="copied('articleSuggested')" /></span> 
                </div>
                </ng-template>
                
            
            </div>
        
           
        
    </div>
</ng-container>
    <div class="btn_block">
        <button class="btn_blue" *ngIf="wizardState.length>1 && !isCompleted" (click)="traversePosition()">Prev </button>
        <button class="btn_blue" *ngIf="traverse==0 && !!headline" (click)="generateWizard('Article')">Create Article</button>
        <button class="btn_blue" *ngIf="isArticleGenerated" (click)="generateWizard('Optimize')">Optimize Article</button>
        <button class="btn_blue" *ngIf="traverse==1  && !isArticleGenerated" (click)="generateWizard('Headline')">Create Headline</button>
        <button class="btn_blue" *ngIf="traverse==2 && wizardState.length !==3" [disabled]="selectedHeadline=='' || selectedHeadline==undefined" (click)="generateWizard('Summary')">Create Summary</button>
        <button class="btn_blue" *ngIf="wizardState.length>2 && !isCompleted" (click)="generateWizard('Completed')">Completed</button>
        <button class="btn_blue" *ngIf="isCompleted" (click)="generateWizard('CreateNew')">Create New</button>
    </div>
    <ng-container *ngIf="isCompleted">
        <div class="aiSuggestionArea">
        <h6 class="pt20">Content Created for Article, Headline and Summary</h6>
        <div class="reacommendedArea articleArea">
        <!-- <ng-container *ngFor="let obj of wizardState"> -->
        <div class="blockArea whiteBg">
            <h6>Article</h6>
            <span>
                <label>
                  <p contenteditable="true" id="article" [innerHTML]="wizardState[0].data"></p>
                </label>
                <span class="copySelction"><img src="../../assets/copy_icon.png" alt="Copy" (click)="copied('article')"/></span>
            </span>
            </div>
            <div class="blockArea whiteBg">
            <h6>Headline</h6>
            <span>
                <label>
                  <p contenteditable="true" id="headline" [innerHTML]="selectedHeadline"></p>
                </label>
                <span class="copySelction"><img src="../../assets/copy_icon.png" alt="Copy" (click)="copied('headline')"/></span>
            </span>
            </div>
            <div class="blockArea whiteBg">
            <h6>Summary</h6>
            <span>
                <label>
                  <p contenteditable="true" id="summary" [innerHTML]="wizardState[2].data"></p>
                </label>
                <span class="copySelction"><img src="../../assets/copy_icon.png" alt="Copy" (click)="copied('summary')"/></span>
            </span>
           
        </div>
    <!-- </ng-container> -->
    </div>
    </div>
    </ng-container>
        </div>  
    </div>
    </div>   
    
</div>
<div class="lightBox" *ngIf="isSearchTopicLoader">
    <div class=""><img src="../../assets/rocket-loader.svg" height="262px"/></div>
        </div>

