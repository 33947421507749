import { Component, Inject, OnInit } from '@angular/core';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { AuthenticationResult, PopupRequest } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpRequestService } from 'src/app/services/http-request.service';
import { User, UserTracker } from '../models/tracker.model';
import { StaticService } from '../services/static.service';
import { NgxToastrService } from '../services/ngx-toastr.service';
import { UtilityService } from '../services/utility.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  isLogin: boolean = false;
  textParam: any = '';
  trackerModel = new UserTracker();
  authHeaders = StaticService.authHeaders;

  username: string = ""
  password: string = ""
  tempCreds: any[] = [
    { id: "admin", password: "Admin@1234" },
    { id: "aisuggest", password: "Ai@1234" }
  ]
  loginError: string = ""

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private httpRequest: HttpRequestService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastr: NgxToastrService,
    private utilityService: UtilityService,
  ) { }

  ngOnInit(): void {
    // this.isUserLoggedIn();
    this.textParam = this.activatedRoute.snapshot.queryParamMap.get('selectedText');
    if (!this.textParam) this.textParam = '';
  }

  isUserLoggedIn() {
    console.log('this.loginDisplay124  :: ', this.authService.instance.getAllAccounts())

    let activeAccount = this.authService.instance.getActiveAccount();

    if (!!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
      // this.router.navigate(['/user-list']);
    }

  }

  loginPopup() {
    localStorage.clear();
    this.isLogin = true;

    this.authService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
      .subscribe((response: AuthenticationResult) => {
        this.authService.instance.setActiveAccount(response.account);
        this.isLogin = true;
        const accessTokenRequest = {
          scopes: ["user.read"],
          account: this.authService.instance.getAllAccounts()[0],
        };

        this.authService.acquireTokenSilent(accessTokenRequest).subscribe((res: any) => {
          let authReqObj = {
            appId: "AI_SUGGESTION_WEB",
            email: res.account.username,
            name: res.account.name,
            userId: res.account.localAccountId,
            identityPlatform: "google",
            accessToken:res.accessToken
          }
          console.log('res=======',res)
          this.checkUserAuth(authReqObj)
        })
      }, (err: any) => {
        console.log('=========', err.errorCode)
        this.isLogin = false;
        if (err.errorCode == 'hash_does_not_contain_known_properties') {
          //this.toastr.error('Micrsoft Authencation Error','kindly relogin',true,5000);
          this.loginPopup()
        }
      });

  }
  userAuthentication(authReqObj: User) {
    let route = this.activatedRoute.snapshot.queryParamMap.get('domainName');
    if (!route) route = 'zeemedia'
    this.trackerModel.user = authReqObj
    this.httpRequest.http_post(environment.config.trackingUrl + '/tracker/record', this.trackerModel, this.authHeaders, undefined).subscribe(
      {
        next: (res) => {
          console.log('userAuthentication', res.body)
          let data = res.body;
          if (!!data) {
            // this.router.navigateByUrl("content-creation" + `?domainName=${route}&selectedText=${this.textParam}`)
          }
        },
        error: (err) => {
          console.log(err);
          this.isLogin = false;
          this.toastr.error('Error', 'Server Error');
        },
        complete: () => { this.isLogin = false; }
      });

  }

  checkUserAuth(authReqObj: any) {
  let route = this.activatedRoute.snapshot.queryParamMap.get('domainName');
    if (!route) route = 'zeemedia'
    this.httpRequest.http_post(environment.config.authenticationUrl +'/auth-service/authenticate' , authReqObj, undefined, undefined).subscribe(
      {
        next: (res) => {
          console.log('userAuthentication', res.body)
          let data = res.body;
          if (!!data) {
            this.utilityService.setToken(data);
            this.router.navigateByUrl("content-creation" + `?domainName=${route}&selectedText=${this.textParam}`)
            this.userAuthentication(authReqObj)//for tracking
          }
        },
        error: (err) => {
          console.log(err);
          this.isLogin=false;
          this.toastr.error('Error',err.error, true, 15000);
        },
        complete: () => { this.isLogin=false;}
      });

  }
  temporaryLogin() {

    let route = this.activatedRoute.snapshot.queryParamMap.get('domainName');
    if (!route) route = 'zeemedia'

    let list = this.tempCreds.filter(e => e.id == this.username);

    if (list.length > 0) {
      if (this.tempCreds.filter(e => e.id == this.username)[0].password == this.password) {
        localStorage.setItem('token', 'access_token')
        this.router.navigateByUrl("content-creation" + `?domainName=${route}&selectedText=${this.textParam}`)
      } else
        this.loginError = "Invalid Credentials"
    } else
      this.loginError = "Invalid Credentials"
  }

  reset() {
    this.username = "";
    this.password = "";
    this.loginError = ""
  }
}