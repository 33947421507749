import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'tags-creation',
  templateUrl: './tags-creation.component.html',
  styleUrls: ['./tags-creation.component.scss']
})
export class TagsCreationComponent implements OnInit {
  tagsObject:string[] = [];
  @ViewChild('keywords') keyWordRef: any;
  @Output() sendTags = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
  }
 /**Method to add new keywords */
 addKeywords(value: any, event: any) {
  let keyword = value.trim();
  if (event.key === 'Enter' && !!keyword) {
    this.tagsObject.push(keyword)
    /**remove duplicate */
    this.tagsObject = [... new Set(this.tagsObject)]
    this.keyWordRef.nativeElement.value = '';
    this.sendTags.emit(this.tagsObject)
  }
}
/** Method to remove existing  keywords */
removeKeyword(index: number) {
  this.tagsObject.splice(index, 1)
  this.sendTags.emit(this.tagsObject)
}
}
