import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { StaticService } from './static.service';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor(private toastr:ToastrService) { }
 openRelatedArticle(relatedUrl:any):void{
  if(!relatedUrl)  this.toastr.error('','Something went wrong, kindly try further')
  else{
    window.open(
      relatedUrl,'_blank'
    );
  }
  }
  setToken(token:any){
    sessionStorage.setItem(StaticService.token,JSON.stringify(token.accessToken))
    sessionStorage.setItem(StaticService.refreshToken,JSON.stringify(token.refreshToken))
 }
}
