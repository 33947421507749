import {  Component, OnInit, ViewChild } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { HttpRequestService } from '../services/http-request.service';
import { environment } from 'src/environments/environment';
import { NgxToastrService } from '../services/ngx-toastr.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import { StaticService } from '../services/static.service';
import { SearchTopicComponent } from '../help-me-write/search-topic/search-topic.component';
import { UtilityService } from '../services/utility.service';

@Component({
  selector: 'app-html-article',
  templateUrl: './article-creation.component.html',
  styleUrls: ['./article-creation.component.scss']
})
export class ArticleCreationComponent implements OnInit {

  public Editor: any = ClassicEditor;
  public config: any = {
    toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'undo', 'redo'],
    // removePlugins: ['CKFinder, EasyImage, Image, ImageCaption, ImageStyle, ImageToolbar, ImageUpload, MediaEmbed, TableToolbar',],
    language: 'en'
  };
  content!: any ;
  isAction: boolean = false;
  isHeadline: boolean = false;
  headlineBody: any = '';
  isSummary: boolean = false;
  summaryBody: any = '';
  isKeyword: boolean = false;
  keywordBody: any = '';

  isBullet: boolean = false;
  bulletBody: any = '';

  isFaq: boolean = false;
  faqBody: any = '';

  isContentQuality: boolean = false;
  contentBody: any = '';
  headline: string = '';
  selectedHeadline: any = '';
  selectedHeadlineIndex: number=0;
  enableFeatures: any = '';
  valueHeading:any = '';
  defaultOutputLang:string ='English'
  defaultTone:string ='Neutral'
  defaultContentType:string ='News Article'
  domainName:any;
  wordLimit!:number
  langList =[];
  toneList =[];
  articleResWrdLimit = [];
  articleDefaultLen:string='';
  contentTypeList =['News Article' , 'Blog Article' , 'How to Guide' , 'Product Review' , 'Product Description' ];
  authHeaders =StaticService.authHeaders;
  textParam:any
  isRetry:boolean =false;
  wizardState:any= []
  articleSuggestedData:any='';
  traverse:number =0;
  isCompleted:boolean =false;
  generatedArticle:any ='';
  isArticleGenerated:boolean =false;
  isArr:boolean =false;
  payload: any = { article: this.content ,dest_lang:"", content_type:"",length:this.articleDefaultLen,tags:""};
  isHelpMeWrite:boolean =false;
  isTopicSearched:boolean =false;
  searchedTopic:string = '';
  similarSearchUrl:string = '';
  tagsObjects:string='';
  @ViewChild('isSearchedPageCalled') isSearchedPageCalled!:SearchTopicComponent;
  isSearchTopicLoader:boolean =false;
  articleCreationDomainConfig:any={};
  constructor(private httpRequest: HttpRequestService, private toastr: NgxToastrService,
    private activatedRoute:ActivatedRoute, private router:Router, private utilityService:UtilityService) { }

  ngOnInit(): void {
    this.domainName = this.activatedRoute.snapshot.queryParamMap.get('domainName');
    this.textParam = this.activatedRoute.snapshot.queryParamMap.get('selectedText');
    this.content = this.textParam;
    this.httpRequest.http_get(environment.config.configUrl +`/${this.domainName}.json`,undefined,undefined).subscribe((data:any) => {
      const response = data.body;
      console.log('article creation',response)
      this.enableFeatures = response['features']
      this.defaultOutputLang = response['defaultLang']
      this.defaultTone = response['defaultTone']
      this.wordLimit = response['wordLimit']
      this.langList = response['targetLang']
      this.toneList = response['tone']
      this.articleResWrdLimit = response['articleResWordLimit']
      this.articleDefaultLen = response['defaulResWrdLimit']
      this.isHelpMeWrite = response['isHelpMeWrite']
      this.articleCreationDomainConfig = response['articleCreation']
    })
  }
 
  

 



  copied(data:any): void {
    let extractContent:any = document.querySelector(`#${data}`)?.textContent;
    if(!!extractContent){
     extractContent = extractContent?.replace('&nbsp;','')
     this.copy(extractContent)
    }else{
      this.toastr.error('','Text does not exist.')
    }
  }
  copiedHeadline(data:any,index:number): void {
    let extractContent:any = document.querySelector(`#${data}${index}`)?.textContent;
     extractContent = extractContent?.replace('&nbsp;','')
   this.copy(extractContent);
  }


 copy(extractContent:any){
  navigator.clipboard.writeText(extractContent).then(() => {
    this.toastr.success('', 'Text Copied to Clipboard');
  }, () => {
    console.error('Failed to copy');
    this.toastr.error('Failed to copy', '');
  });
 }
  changeTone(e:any){
    this.defaultTone = e.target.value
  }
  changeOutputLang(e:any){
    this.defaultOutputLang = e.target.value
  }
  changeArticleWordLimit(e:any){
    this.articleDefaultLen = e.target.value
  }
  trimContent(content:string){
  //  console.log('content',content)
   if(this.content ){
    this.content = this.content.replace(/ {2,}/g, ' ')
   }
  }
  pageTab(tab: any){
    console.log(tab);
  }
  changeContentType(event:any){
   this.defaultContentType = event.target.value;
  }

  generateWizard(type:string){
    switch(type){
      case 'Headline':
        let extractContent:any = document.querySelector(`#articleSuggested`)?.innerHTML;
        this.generatedArticle = extractContent?.replace('&nbsp;','')
        if(!this.generatedArticle){
          return this.toastr.error('','Kindly Generate Article')
        }else{
        this.isAction= true;
        this.isCompleted =false;
        this.selectedHeadline = "";
        if(this.wizardState[0]) this.wizardState[0]['data'] = this.generatedArticle;
        this.generateHeadline();
        }
        break;

      case 'Summary':
        this.isAction= true;
        this.isCompleted =false;
        this.isArr = false;
        let radioElement:any = document.getElementById(`headline${this.selectedHeadlineIndex}`);
        let innerHTML = radioElement.innerHTML;
        this.selectedHeadline = innerHTML;
        // console.log('selectedHeadline',this.selectedHeadline)
        this.generateSummary();
        break;

      case 'Article':
        this.isAction= true;
        this.isCompleted =false;
        this.isArr = false;
        // this.generateArticle('/aiContent','Article');
        this.generateArticle('/article','Article');
        break;

      case 'Optimize':
        this.isAction= true;
        this.isCompleted =false;
        this.isArr = false;
        this.generateArticle('/humanContent','Optimize');
        break;

      case 'Completed':
        let content:any = document.querySelector(`#articleSuggested`)?.innerHTML;
        this.generatedArticle = content?.replace('&nbsp;','');
        if(this.wizardState[2]) this.wizardState[2]['data'] = this.generatedArticle;
        this.isArr = false;
      this.isCompleted = true;
        break;
      case 'CreateNew':
      this.isCompleted = false;
      this.wizardState =[];
      this.traverse =0;
      this.headline ="";
      this.articleSuggestedData = '';
      this.isArr = false;
      this.tagsObjects ='';
        break;
    }
  }
  generateHeadline(): void {
    // this.payload.article = this.content;
    let extractContent:any = document.querySelector(`#articleSuggested`)?.innerHTML;
    this.generatedArticle = extractContent?.replace('&nbsp;','')
    // this.payload.article = this.generatedArticle;
    this.payload.article = extractContent;
    this.payload.dest_lang = this.defaultOutputLang
    this.payload.content_type = this.defaultContentType
    this.payload.tone = this.defaultTone
    console.log('generateHeadline payload', this.payload)
    this.httpRequest.http_post(environment.config.contentbaseUrl + '/headline', this.payload, this.authHeaders, undefined).subscribe({
      next: (res: any) => {
        this.headline ="Headline";
        this.articleSuggestedData = res?.body?.titles;
        Array.isArray(this.articleSuggestedData)
        this.isArr = true;
        console.log('generateHeadline response', res)
        this.traverse = 2 ;
        if(this.wizardState[1]) this.wizardState = [{'data': this.articleSuggestedData}]
        else this.wizardState.push({'data': this.articleSuggestedData})
       

      },
      error: (err) => {
        console.log('generateHeadline error',err);
        this.toastr.error('', 'Something went wrong');
        this.isAction = false;
        this.isRetry =true;
      },
      complete: () => {
        this.isAction = false;
      }
    });
  }

  generateSummary(): void {
    // this.payload.article = this.content;
    // this.payload.article = this.selectedHeadline; if dont want to generate summary based on headline
    this.payload.article = this.generatedArticle; //if  want to generate summary based on article
    
    this.payload.dest_lang = this.defaultOutputLang
    this.payload.content_type = this.defaultContentType
    this.payload.tone = this.defaultTone
    
    console.log('generateSummary payload', this.payload)
    this.httpRequest.http_post(environment.config.contentbaseUrl + '/summarize', this.payload, this.authHeaders, undefined).subscribe({
      next: (res: any) => {
        this.headline ="Summary"
        this.articleSuggestedData = res?.body?.summary;
        console.log('generateSummary response',  res)
        this.traverse = 3;
        if(this.wizardState[2]) this.wizardState = [{'data': this.articleSuggestedData}]
        else this.wizardState.push({'data': this.articleSuggestedData})
       
        if(!this.articleSuggestedData){
          this.articleSuggestedData ='';
          this.isRetry =true;
          this.isAction = false;
          return this.toastr.error('','Kindly try after a while.')
        }
        this.isRetry =false;
        this.isAction = false;
      },
      error: (err) => {
        console.log('generateSummary',err);
        this.toastr.error('', 'Something went wrong');
        this.isAction = false;
        this.isRetry =true;
      },
      complete: () => {
        this.isAction = false;
      }
    });
  }
  generateArticle(route:string, key:string): void {
    if(key=='Optimize')
    this.payload.article = this.generatedArticle
    else this.payload.article = this.content;
    
    this.payload.dest_lang = this.defaultOutputLang
    this.payload.content_type = this.defaultContentType
    this.payload.tone = this.defaultTone;
    this.payload.length = this.articleDefaultLen;
    this.payload.tags = this.tagsObjects;
  
    console.log('generateArticle payload', this.payload)
    //this.httpRequest.http_post(environment.config.articlebaseUrl + route, this.payload, this.authHeaders, undefined).subscribe({
    this.httpRequest.http_post(environment.config.newArticlebaseUrl + route, this.payload, this.authHeaders, undefined).subscribe({
      next: (res: any) => {
        this.headline ="Article";
        this.articleSuggestedData = res?.body?.article;
        
        if(key=='Optimize')this.isArticleGenerated = false;
        else this.isArticleGenerated = true;
        this.traverse = 1;
        console.log('generateArticle response',  res)
        this.generatedArticle = this.articleSuggestedData;
        if(this.wizardState[0]) this.wizardState = [{'data': this.articleSuggestedData}]
        else this.wizardState.push({'data': this.articleSuggestedData})
       
        if(!this.articleSuggestedData){
          this.articleSuggestedData ='';
          this.isRetry =true;
          this.isAction = false;
          return this.toastr.error('','Kindly try after a while.')
        }
        this.isRetry =false;
        this.isAction = false;
      },
      error: (err) => {
        console.log('generateSummary',err);
        this.toastr.error('', 'Something went wrong');
        this.isAction = false;
        this.isRetry =true;
      },
      complete: () => {
        this.isAction = false;
      }
    });
  }
  traversePosition(){
    let index = this.traverse;
    if(index>0){
      this.traverse =index-1;
      let nodeIndex =  this.traverse-1;
      this.traverse =nodeIndex;
      if( this.traverse ==0) {this.headline ='Article'; this.isArr =false;}
      else if( this.traverse ==1) {this.headline ='Headline';this.isArr = true}
      else if( this.traverse ==2) this.headline ='Summary'
      this.articleSuggestedData = this.wizardState[nodeIndex].data
      this.wizardState.splice(this.traverse+1,1)
      this.traverse =nodeIndex+1; //to switch next step heading& action button ex: headline->summary->article
      console.log('traversePosition',index-1)
    }else{
      console.log(index)
      this.traverse =0;
      this.headline ='Article'
      this.articleSuggestedData = this.wizardState[this.traverse].data
    }
  }
  clickTab(tab:number){
    let route:any = '';
    if(tab==0){
      route = this.activatedRoute.snapshot.queryParamMap.get('domainName');
      this.router.navigateByUrl("content-creation"+`?domainName=${route}`)
  
    }
  }
  setSelctedHeadline(headline:string,index:number):void{
    this.selectedHeadline =  headline;
    this.selectedHeadlineIndex = index;
  }
  clickTabPage(tab:number){
    let route:any = '';
   
    if(tab == 1){
      route = this.activatedRoute.snapshot.queryParamMap.get('domainName');
      this.router.navigateByUrl("article-creation"+`?domainName=${route}`)
    } else {
      route = this.activatedRoute.snapshot.queryParamMap.get('domainName');
      this.router.navigateByUrl("daily-trend"+`?domainName=${route}`)
    }
  
  }
  textAreaAdjust(element:any) {
    element.style.height = "1px";
    element.style.height = (25+element.scrollHeight)+"px";
  }
 
  searchTopic(topic:string):void{
    this.searchedTopic = topic;
    this.isTopicSearched = true;
   this.getSimilarSearch(this.searchedTopic)
    }

    /** searching similar search based on topic */
  getSimilarSearch(topic:string):void{
    this.isSearchTopicLoader =!this.isSearchTopicLoader;
      this.httpRequest.http_post(environment.config.helpMeWriteUrl +'/search',{topic})
      .subscribe({
      next:(res:any)=>{
        console.log('getSearchItemmDetails res', res)
        this.similarSearchUrl=res?.body?.search_results?.similar_searches;
        console.log('getSearchItemmDetails',this.similarSearchUrl)
        this.utilityService.openRelatedArticle(this.similarSearchUrl);
      },
      error: (err)=>{console.log('getSearchItemmDetails error',err);
      this.toastr.error('','Something went wrong, try for a while')},
      complete: ()=>{console.log('getSearchItemmDetails completed'); 
      this.isSearchTopicLoader = !this.isSearchTopicLoader;},
      })
    }
    /** received from child comp */
    receivedTags(event:string[]):void{
      if(event)
       this.tagsObjects = event.toString();
    }
}
