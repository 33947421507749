import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StaticService {
  public static token:string = 'token';
  public static refreshToken = 'refreshToken';
  public static authHeaders=  new HttpHeaders({Authorization:'Basic '+ btoa(environment.config.basicAuth.userName +':'+environment.config.basicAuth.password)})

}
