<div class="inputField">
    <input type="text" #keywords (keydown)="addKeywords(keywords.value,$event)"
        placeholder="Add Tags here..." class="textBox">
    <div class="keywords" *ngIf="tagsObject.length>0">
        <ul >
            <li *ngFor="let list of tagsObject;let i=index;" class="keywordTag">
                <span>{{list}} <em (click)="removeKeyword(i)"><i
                            class="icon-close"></i></em></span>
            </li>
        </ul>

    </div>
</div>