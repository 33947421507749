import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpRequestService } from 'src/app/services/http-request.service';
import { NgxToastrService } from 'src/app/services/ngx-toastr.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-search-topic',
  templateUrl: './search-topic.component.html',
  styleUrls: ['./search-topic.component.scss']
})
export class SearchTopicComponent implements OnInit {
  searchTopicList:any=[];
  isAction:boolean =false;
  @Input() searchedTopic:any;
  constructor(private httpRequest: HttpRequestService, private toastr: NgxToastrService,
    private activatedRoute:ActivatedRoute, private router:Router) { }

  ngOnInit(): void {
    this.getSearchItemmDetails(this.searchedTopic)
  } 
  getSearchItemmDetails(topic:string):void{
    this.searchTopicList =[];
    this.isAction = !this.isAction;
    this.httpRequest.http_post(environment.config.helpMeWriteUrl +'/search',{topic})
    .subscribe({
     next:(res:any)=>{
       console.log('getSearchItemmDetails res', res)
       this.searchTopicList =res?.body?.search_results;
       console.log('getSearchItemmDetails',this.searchTopicList)
     },
     error: (err)=>{console.log('getSearchItemmDetails error',err);
     this.toastr.error('','Something went wrong, try for a while')},
     complete: ()=>{console.log('getSearchItemmDetails completed'); 
     this.isAction = !this.isAction;},
    })
   }
   openRelatedArticle(relatedUrl:any):void{
    window.open(
      relatedUrl,'_blank'
    );
  }
  writeAboutTopic(topic:any):void{
    let domainName:any = this.activatedRoute.snapshot.queryParamMap.get('domainName');
    this.router.navigateByUrl("article-creation"+`?domainName=${domainName}&selectedText=${topic}`)
  }
}
