<div class="aiContainer">
<header>
    <h2>Ask to Zeeni</h2>
    <div class="pageTab">
        <ul>
            <li (click)="clickTabPage(1)">Content Creation</li>
            <li (click)="clickTabPage(2)" class="active" *ngIf="isHelpMeWrite">Help me write</li>
        </ul>
    </div>
</header>
<div class="workArea pad20">
<div class="relative pb20">
            <div class="exporeTopic">   
                    <input type="text" #text placeholder="Explore topics here..." (keyup.enter)="searchTopic(text.value)"/>
                    <span class="searchIcon" (click)="searchTopic(text.value)">
                        <img src="../../../assets/seachIcon.png" alt=" " />
                    </span>
            </div>
        
    </div>
    <ng-container *ngIf="isTopicSearched" class="search-topic">
        <app-search-topic #isSearchedPageCalled [searchedTopic]="searchedTopic"></app-search-topic>
       </ng-container>
       <ng-container class="without-search" *ngIf="!isTopicSearched">
<div class="flex gap20 relative">
    <div class="bodyContainer pl20 pr20 pb20 pt0">
       
        <div class="tabs">
            <div class="tab active">daily search trends</div>
            <div class="tab" (click)="clickTab(1)">realtime search trends</div>
        </div>
        
            <div class="listArticle">
                <ul>
                    <li *ngFor="let item of dailyTrendingItems;let i=index;"
                    [class.active]="selectedIndex === i"
                    (click)="selectItem(i)">
                        <ng-container *ngIf="dailyTrendingItems.length>0">
                            <div class="thumbImage">
                                <img [src]="item?.images" alt="" />
                            </div>
                            <div class="listDetail">
                                <h4>{{item?.topic}}</h4>
                                <p>{{item?.snippet}}</p>
                                <!-- <div class="agencyTime"><span class="agency">India Today</span><span class="time">7g ago</span></div> -->
                            </div>
                        </ng-container>
                        
                    </li>
                </ul>
            </div>
            
            
              
            
    </div>
    
    <div class="SuggestionPart" *ngIf="!!dailyItemsDetails.topic">
        
       
       
        <div class="aiSuggestionArea helpMeWrite">
        <h4 class="pt20">Why is <span>{{dailyItemsDetails.topic}}</span> trending?</h4>
        
        <div class="dailySearchBox">
            <p class="suggesionArea">{{dailyItemsDetails.reason}}</p>  
            <p class="writeAbout" ><span (click)="writeAboutTopic(dailyItemsDetails.reason)"><img src="../../assets/write_topic.png" alt="" />Write About This Topic</span></p>
            <h4>Idea about this topic</h4>
            <ul>
                <li *ngFor="let list of dailyItemsDetails.ideas">
                    {{list}}
                </li>
            </ul>
            <h5>Related News</h5>
            <div class="listRecommended">
                <ul>
                    <li *ngFor="let item of dailyItemsDetails.search_results;">
                        <div class="thumbImage">
                            <img [src]="item?.image" alt="" />
                        </div>
                        <div class="listDetail" (click)="openRelatedArticle(item.url)">
                            <h6>{{item?.title}}</h6>
                            <p>{{item?.snippet}}</p>
                            <!-- <div class="agencyTime"><span class="agency">India Today</span><span class="time">7g ago</span></div> -->
                        </div>
                    </li>
                   
                </ul>
            </div>

        </div>
        
</div>
    </div>  
</div>
</ng-container>
</div>
</div>