import {  Component, OnInit } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { HttpRequestService } from '../services/http-request.service';
import { environment } from 'src/environments/environment';
import { NgxToastrService } from '../services/ngx-toastr.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StaticService } from '../services/static.service';

@Component({
  selector: 'content-creation',
  templateUrl: './content-creation.component.html',
  styleUrls: ['./content-creation.component.scss'],
})
export class ContentCreationComponent implements OnInit{
  public Editor: any = ClassicEditor;
  public config: any = {
    toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'undo', 'redo'],
    // removePlugins: ['CKFinder, EasyImage, Image, ImageCaption, ImageStyle, ImageToolbar, ImageUpload, MediaEmbed, TableToolbar',],
    language: 'en'
  };
  content!: any ;
  payload: any = { article: this.content ,dest_lang:"", tone:"",tags:""};
  botData: any = '';
  bulletData: any = [];
  scoreCount: any;
  scoreData: any = [];
  paraPhraseData: any;
  seoData: any = [];
  expandedArticle: any = '';
  suggestedHeadline: any = [];
  translatedText: any = '';
  suggestedSummary: any = '';
  qualitySuggestion: any = [];
  faqsData: any = [];
  isAction: boolean = false;
  isHeadline: boolean = false;
  headlineBody: any = '';
  isSummary: boolean = false;
  summaryBody: any = '';
  isKeyword: boolean = false;
  keywordBody: any = '';

  isBullet: boolean = false;
  bulletBody: any = '';

  isFaq: boolean = false;
  faqBody: any = '';

  isContentQuality: boolean = false;
  contentBody: any = '';
  headline: string = '';
  selectedHeadline: any = '';
  enableFeatures: any = '';
  valueHeading:any = '';
  defaultOutputLang:string ='English'
  defaultTone:string ='Neutral'
  domainName:any;
  wordLimit!:number
  langList =[];
  toneList =[];
  authHeaders =StaticService.authHeaders;
  textParam:any
  isRetry:boolean =false;
  isHelpMeWrite:boolean =false;
  tagsObjects:string='';
  webStory:any =[];
  noOfSlide:any=[];
  defaultSlideNo:number=0;
  contentCreationDomainConfig:any ={};
  constructor(private httpRequest: HttpRequestService, private toastr: NgxToastrService,
    private activatedRoute:ActivatedRoute, private router:Router) { }

  ngOnInit(): void {
    this.domainName = this.activatedRoute.snapshot.queryParamMap.get('domainName');
    this.textParam = this.activatedRoute.snapshot.queryParamMap.get('selectedText');
    this.content = this.textParam;
    this.httpRequest.http_get(environment.config.configUrl +`/${this.domainName}.json`,undefined,undefined).subscribe((data:any) => {
      const response = data.body;
      console.log('content creation',response)
      this.enableFeatures = response['features']
      this.defaultOutputLang = response['defaultLang']
      this.defaultTone = response['defaultTone']
      this.wordLimit = response['wordLimit']
      this.langList = response['targetLang']
      this.toneList = response ['tone']
      this.isHelpMeWrite = response['isHelpMeWrite']
      this.contentCreationDomainConfig = response['contentCreation']
    })
  }
 
  change(type: string): void {
    switch (type) {
      case "Headline":
        this.selectedHeadline ='';
        this.headline ="Headlines"
        this.isAction= true;
        this.valueHeading = type;
        this.generateHeadline();

        break;

      case "Summary":
        this.headline ="Headlines"
        this.isAction= true;
        this.valueHeading = type;
        this.generateSummary();
        break;

      case "FAQ":
        this.headline = "FAQs";
        this.isAction = true;
        this.valueHeading = type;
        this.generateFAQ();
        break;

      case "Write_More":
        this.headline ="Expanded Article"
        this.isAction= true;
        this.valueHeading = type;
        this.expandArticle()
        break;

      case "SEO":
        this.headline ="SEO Keywords"
        this.isAction= true;
        this.valueHeading = type;
        this.generateKeywords();
        break;
      case "Quality" :
        this.headline ="Article Quality"
        this.isAction= true;
        this.valueHeading = type;
        this.qualityCheck();
        break;
      case "Bullet" :
        this.headline ="Article converted into Bullets"
        this.isAction= true;
        this.valueHeading = type;
        this.bulletPoints();
        break;
      case "Score" :
        this.headline ="Article Quality Score"
        this.isAction= true;
        this.valueHeading = type;
        this.generateScore();
        break;
      case "Paraphrase" :
        this.headline ="Article Paraphrased"
        this.isAction= true;
        this.valueHeading = type;
        this.generateParaphrase();
        break;

      case "Translate":
        this.selectedHeadline ='';
        this.headline ="Translate"
        this.isAction= true;
        this.valueHeading = type;
        this.contentTranslate();

        break;

      case "Webstory":
        this.headline ="Webstory"
        this.valueHeading = type;
        this.isAction= true;
        const index= this.enableFeatures.findIndex((feature:any)=>feature.action ==type);
        this.noOfSlide = this.enableFeatures[index]?.noOfSlide;
        if(this.defaultSlideNo==0)
        this.toastr.info('','Kindly Select No Of Slide');
        else this.generateWebStory() // because slide no exist

        break;
      default:
        break;
    }
  }

  resetData(): void {
    this.bulletData = [];
    this.seoData = [];
    this.expandedArticle = '';
    this.suggestedHeadline = [];
    this.translatedText = '';
    this.qualitySuggestion = '';
    this.faqsData = [];
    this.paraPhraseData = '';
    this.scoreCount = '';
    this.scoreData = [];
    this.suggestedSummary = '';
    this.webStory =[];
  }

  generateFAQ(): void {
    this.payload.article = this.content;
    this.payload.dest_lang = this.defaultOutputLang
    this.payload.tone = this.defaultTone
    this.resetData();
    console.log('generateFAQ payload', this.payload)
    this.httpRequest.http_post(environment.config.contentbaseUrl + '/faq', this.payload, this.authHeaders, undefined).subscribe({
      next: (res: any) => {
        console.log('generateFAQ response',res)
        this.faqsData = res.body?.FAQs?.length>0?res.body.FAQs: res?.body?.faqs;
        if(!this.faqsData){
          this.faqsData =[]
          this.isRetry =true;
          this.isAction = false;
          return this.toastr.error('','Kindly try after a while.')
        }
        this.isAction = false;
      },
      error: (err) => {
        console.log(err);
        this.isAction = false;
      },
      complete: () => {
        this.isAction = false;
        this.isRetry =false;
      }
    });
  }

  generateHeadline(): void {
    // console.log("content", this.content);
    this.payload.article = this.content;
    this.payload.dest_lang = this.defaultOutputLang
    this.payload.tone = this.defaultTone
    this.resetData();
    console.log('generateHeadline payload', this.payload)
    this.httpRequest.http_post(environment.config.contentbaseUrl + '/headline', this.payload, this.authHeaders, undefined).subscribe({
      next: (res: any) => {
        this.suggestedHeadline = res?.body?.titles;
        console.log('generateHeadline response', res)
        if(!this.suggestedHeadline || this.suggestedHeadline.length==0) {
          this.isRetry =true;
          this.isAction = false;
          this.suggestedHeadline =[];
          return this.toastr.error('','Kindly try after a while.')
        } 
        this.isRetry =false;
        this.isAction = false;
      },
      error: (err) => {
        console.log('generateHeadline error',err);
        this.toastr.error('', 'Something went wrong');
        this.isAction = false;
        this.isRetry =true;
      },
      complete: () => {
        this.isAction = false;
      }
    });
  }

  generateSummary(): void {
    this.payload.article = this.content;
    this.payload.dest_lang = this.defaultOutputLang
    this.payload.tone = this.defaultTone
    this.resetData();
    console.log('generateSummary payload', this.payload)
    this.httpRequest.http_post(environment.config.contentbaseUrl + '/summarize', this.payload, this.authHeaders, undefined).subscribe({
      next: (res: any) => {
        this.suggestedSummary = res?.body?.summary;
        console.log('generateSummary response',  res)
        if(!this.suggestedSummary){
          this.suggestedSummary ='';
          this.isRetry =true;
          this.isAction = false;
          return this.toastr.error('','Kindly try after a while.')
        }
        this.isRetry =false;
        this.isAction = false;
      },
      error: (err) => {
        console.log('generateSummary',err);
        this.toastr.error('', 'Something went wrong');
        this.isAction = false;
        this.isRetry =true;
      },
      complete: () => {
        this.isAction = false;
      }
    });
  }

  expandArticle(): void {
    this.payload.article = this.content;
    this.payload.dest_lang = this.defaultOutputLang
    this.payload.tone = this.defaultTone
    this.resetData();
    console.log('expandArticle payload', this.payload)
    this.httpRequest.http_post(environment.config.contentbaseUrl + '/assist', this.payload, this.authHeaders, undefined).subscribe({
      next: (res: any) => {
        this.expandedArticle = JSON?.parse(res?.body?.expanded_text);
        console.log('expandArticle response',  res)
        if(!this.expandedArticle){
          this.expandedArticle ='';
          this.isRetry =true;
          this.isAction = false;
          return this.toastr.error('','Kindly try after a while.')
        }
        this.isRetry =false;
        this.isAction = false;
      },
      error: (err) => {
        console.log('expandArticle',err);
        this.toastr.error('', 'Something went wrong');
        this.isAction = false;
        this.isRetry =true;
      },
      complete: () => {
        this.isAction = false;
      }
    });
  }

  generateKeywords(): void {
    this.payload.article = this.content;
    this.payload.dest_lang = this.defaultOutputLang
    this.payload.tone = this.defaultTone
    this.resetData();
    console.log('generateKeywords payload', this.payload)
    this.httpRequest.http_post(environment.config.contentbaseUrl + '/seo', this.payload, this.authHeaders, undefined).subscribe({
      next: (res: any) => {
        this.seoData = res?.body?.keywords;
        console.log('generateKeywords response',  res)
        if(!this.seoData || this.seoData.length==0){
          this.seoData =[];
          this.isRetry =true;
          this.isAction = false;
          return  this.toastr.error('','Kindly try after a while.')
        }
        this.isRetry =false;
        this.isAction = false;
      },
      error: (err) => {
        console.log('generateKeywords',err);
        this.toastr.error('', 'Something went wrong');
        this.isAction = false;
        this.isRetry =true;
      },
      complete: () => {
        this.isAction = false;
      }
    });
  }

  qualityCheck(): void {
    this.payload.article = this.content;
    this.payload.dest_lang = this.defaultOutputLang
    this.payload.tone = this.defaultTone
    this.resetData();
    console.log('qualityCheck payload', this.payload)
    this.httpRequest.http_post(environment.config.contentbaseUrl + '/check', this.payload, this.authHeaders, undefined).subscribe({
      next: (res: any) => {
        this.qualitySuggestion = res?.body?.suggestions;
        console.log('qualityCheck response',  res)
        if(!this.qualitySuggestion || this.qualitySuggestion.length==0){
          this.qualitySuggestion =[]
          this.isRetry =true;
          this.isAction = false;
          return this.toastr.error('','Kindly try after a while.')
        }
        this.isRetry =false;
        this.isAction = false;
      },
      error: (err) => {
        console.log('qualityCheck',err);
        this.toastr.error('', 'Something went wrong');
        this.isAction = false;
      },
      complete: () => {
        this.isAction = false;
        this.isRetry =false;
      }
    });
  }

  bulletPoints(): void {
    this.payload.article = this.content;
    this.payload.dest_lang = this.defaultOutputLang
    this.payload.tone = this.defaultTone
    this.resetData();
    this.httpRequest.http_post(environment.config.contentbaseUrl + '/bullets', this.payload, this.authHeaders, undefined).subscribe({
      next: (res: any) => {
        this.bulletData = res?.body?.bullet_points;
        console.log('bulletPoints response',  res)
        if(!this.bulletData && this.bulletData.length==0){
          this.bulletData = []
          this.isRetry =true;
          this.isAction = false;
          return this.toastr.error('','Kindly try after a while.')
        }
        this.isRetry =false;
        this.isAction = false;
      },
      error: (err) => {
        console.log('bulletPoints',err);
        this.toastr.error('', 'Something went wrong');
        this.isAction = false;
      },
      complete: () => {
        this.isAction = false;
        this.isRetry =false;
      }
    });
  }

  generateScore(): void {
    this.payload.article = this.content;
    this.payload.dest_lang = this.defaultOutputLang
    this.payload.tone = this.defaultTone
    this.httpRequest.http_post(environment.config.contentbaseUrl + '/score', this.payload, this.authHeaders, undefined).subscribe({
      next: (res: any) => {
        this.scoreData = [res?.body?.scores];
        console.log('generateScore response',  res)
        if(!this.scoreData && this.scoreData.length==0){
          this.scoreData = []
          this.isRetry =true;
          this.isAction = false;
          return this.toastr.error('','Kindly try after a while.')
        }
        this.isRetry =false;
        this.isAction = false;
      },
      error: (err) => {
        console.log('generateScore',err);
        this.toastr.error('', 'Something went wrong');
        this.isAction = false;
      },
      complete: () => {
        this.isAction = false;
        this.isRetry =false;
      }
    });
  }

  generateParaphrase(): void {
    this.payload.article = this.content;
    this.payload.dest_lang = this.defaultOutputLang
    this.payload.tone = this.defaultTone
    this.resetData();
    this.httpRequest.http_post(environment.config.contentbaseUrl + '/paraphrase', this.payload, this.authHeaders, undefined).subscribe({
      next: (res: any) => {
        this.paraPhraseData = res?.body?.paraphrase;
        console.log('generateParaphrase response',  res)
        if(!this.paraPhraseData){
          this.paraPhraseData = ''
          this.isRetry =true;
          this.isAction = false;
          return this.toastr.error('','Kindly try after a while.')
        }
        this.isRetry =false;
        this.isAction = false;
      },
      error: (err) => {
        console.log('generateParaphrase',err);
        this.toastr.error('', 'Something went wrong');
        this.isAction = false;
        this.isRetry =true;
      },
      complete: () => {
        this.isAction = false;
      }
    });
  }
  contentTranslate(): void {
    // console.log("content", this.content);
    this.payload.article = this.content;
    this.payload.dest_lang = this.defaultOutputLang
    this.payload.tone = this.defaultTone
    this.resetData();
    console.log('translated payload', this.payload)
    this.httpRequest.http_post(environment.config.articlebaseUrl + '/translate', this.payload, this.authHeaders, undefined).subscribe({
      next: (res: any) => {
        this.translatedText = res?.body?.translated_text;
        console.log('generateHeadline response', res)
        if(!this.translatedText) {
          this.isRetry =true;
          this.isAction = false;
          this.translatedText ='';
          return this.toastr.error('','Kindly try after a while.')
        } 
        this.isRetry =false;
        this.isAction = false;
      },
      error: (err) => {
        console.log('contentTranslate error',err);
        this.toastr.error('', 'Something went wrong');
        this.isAction = false;
        this.isRetry =true;
      },
      complete: () => {
        this.isAction = false;
      }
    });
  }

  generateWebStory(): void {
    this.payload.article = this.content;
    this.payload.dest_lang = this.defaultOutputLang
    // this.payload.tone = this.defaultTone;
    this.payload.tags = this.tagsObjects;
    this.payload["n_slides"]= this.defaultSlideNo;
    this.resetData();
    console.log('generateWebStory payload', this.payload)
    this.httpRequest.http_post(environment.config.articlebaseUrl + '/webstory', this.payload, this.authHeaders, undefined).subscribe({
      next: (res: any) => {
        this.webStory = res?.body?.web_stories;
        console.log('generateWebStory response',  res)
        if(!this.webStory){
          this.isRetry =true;
          this.isAction = false;
          return this.toastr.error('','Kindly try after a while.')
        }
        this.isRetry =false;
        this.isAction = false;
      },
      error: (err) => {
        console.log('generateWebStory',err);
        this.toastr.error('', 'Something went wrong');
        this.isAction = false;
        this.isRetry =true;
      },
      complete: () => {
        this.isAction = false;
      }
    });
  }
  copied(data:any): void {
    navigator.clipboard.writeText(data).then(() => {
      this.toastr.success('', 'Text Copied to Clipboard');
    }, () => {
      console.error('Failed to copy');
      this.toastr.error('Failed to copy', '');
    });
  }

  acceptData(type: string): void {
    switch (type) {
      case "Headline":
        if (!this.selectedHeadline) {
          this.toastr.error("", "Kindly Select Headline");
        } else {
          this.isHeadline = true;
          this.headlineBody = this.selectedHeadline;
          this.copied(this.selectedHeadline);
        }
        break;

      case "SEO":
        this.isKeyword = true;
        const keywords = document.querySelector('#keywords')?.innerHTML;
        this.keywordBody = keywords;
        this.copied(this.seoData?.toString());
        break;

      case "FAQ":
        this.isFaq = true;
        const faq = document.querySelector('#faqs')?.innerHTML;
        this.faqBody = faq;
        let faqObj:any =[]
        for(let i in this.faqsData){
         faqObj.push(`Q${+i+1}: ${this.faqsData[i]['question']}`)
         faqObj.push(`Ans${+i+1}: ${this.faqsData[i]['answer']}`)
        }
        console.log('faqObj',faqObj)
        this.copied(faqObj);
        break;

      case "Bullet":
        this.isBullet = true;
        const bullet = document.querySelector('#bullets')?.innerHTML;
        this.bulletBody = bullet;
        let bulletsPoint =[]
        for(let i in this.bulletData){
          bulletsPoint.push(`${+i+1})  ${this.bulletData[i]}`)
        }
        this.copied(bulletsPoint);
        break;
        
      case "Write_More":
        const expandArticle :any=[];
        expandArticle.push(`Headline: ${this.expandedArticle?.output?.headline}`)
        expandArticle.push(`Summary: ${this.expandedArticle?.output?.summary}`)
        expandArticle.push(`Article: ${this.expandedArticle?.output?.article}`)
        // expandArticle.push(`Prompt: ${this.expandedArticle?.output?.prompt}`)
        this.copied(expandArticle);
        break;
      case "Paraphrase":
        const elementId = "#" + type.toLowerCase().replace("_", "-");
        const content = document.querySelector(elementId)?.innerHTML;
        this.content = content;
        this.copied(this.content);
        break;

      case "Summary":
        this.isSummary = true;
        const summarize = document.querySelector('#summary')?.innerHTML;
        this.summaryBody = summarize;
        this.copied(this.summaryBody);
        break;

      case "Translate":
        const translated = document.querySelector('#translated')?.textContent;
        this.copied(translated);
        break;

      case "Quality":
        const qualityCheck=[];
        for(let i in this.qualitySuggestion){
          qualityCheck.push(`${+i+1})  ${this.qualitySuggestion[i]}`)
        }
        this.copied(qualityCheck);
        break;
    }
  }

  changeTone(e:any){
    this.defaultTone = e.target.value
  }
  changeOutputLang(e:any){
    this.defaultOutputLang = e.target.value
  }
  changeSlideNo(e:any){
    this.isAction= true;
    this.defaultSlideNo = +(e.target.value)
    this.generateWebStory();
  }
  trimContent(content:string){
  //  console.log('content',content)
   if(this.content ){
    this.content = this.content.replace(/ {2,}/g, ' ')
   }
  }
  pageTab(tab: any){
    console.log(tab);
  }
clickTab(tab:number){
  let route:any = '';
 
  if(tab == 1){
    route = this.activatedRoute.snapshot.queryParamMap.get('domainName');
    this.router.navigateByUrl("article-creation"+`?domainName=${route}`)
  }

}

clickTabPage(tab:number){
  let route:any = '';
 
  if(tab == 1){
    route = this.activatedRoute.snapshot.queryParamMap.get('domainName');
    this.router.navigateByUrl("article-creation"+`?domainName=${route}`)
  } else {
    route = this.activatedRoute.snapshot.queryParamMap.get('domainName');
    this.router.navigateByUrl("daily-trend"+`?domainName=${route}`)
  }

}
/** received from child comp */
receivedTags(event:string[]):void{
  if(event)
   this.tagsObjects = event.toString();
}
}
