import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// import { HelpMeWriteComponent } from './help-me-write/help-me-write.component';
import { ContentCreationComponent } from './content-creation/content-creation.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './services/auth.guard.service';
import { ArticleCreationComponent } from './article-creation/article-creation.component';
import { DailySearchTrendComponent } from './help-me-write/daily-search-trend/daily-search-trend.component';
import { RealTimeTrendComponent } from './help-me-write/real-time-trend/real-time-trend.component';

const routes: Routes = [
  {path:'content-creation', component:ContentCreationComponent, canActivate:[AuthGuard]},
  // {path:'help-me-write', component:HelpMeWriteComponent, canActivate:[AuthGuard]}, // Remove To do
  {path:'article-creation', component:ArticleCreationComponent, canActivate:[AuthGuard]}, // Remove To do
  {path:'daily-trend', component:DailySearchTrendComponent, canActivate:[AuthGuard]}, // Remove To do
  {path:'realtime-trend', component:RealTimeTrendComponent, canActivate:[AuthGuard]}, // Remove To do
  {path:'article-creation', component:ArticleCreationComponent, canActivate:[AuthGuard]}, // Remove To do
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component:LoginComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    useHash: true,
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
