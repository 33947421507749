import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NgxToastrService {

  constructor(private toastr: ToastrService) { }

  success(title: string, message: string, closeButton: boolean=true, timeOut: number=2000) {
    this.toastr.success(message, title, {
      timeOut: timeOut,
      closeButton: closeButton
    });
  }

  error(title: string, message: string, closeButton: boolean=true, timeOut: number=2000) {
    this.toastr.error(message, title, {
      timeOut: timeOut,
      closeButton: closeButton
    });
  }

  info(title: string, message: string, closeButton: boolean=true, timeOut: number=2000) {
    this.toastr.info(message, title, {
      timeOut: timeOut,
      closeButton: closeButton
    });
  }
  
  warning(title: string, message: string, closeButton: boolean=true, timeOut: number=2000) {
    this.toastr.warning(message, title, {
      timeOut: timeOut,
      closeButton: closeButton
    });
  }

}
