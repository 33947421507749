import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core'
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor  {

  constructor(private injector: Injector) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token: any = localStorage.getItem('token');

    let tokenizeReq = req.clone({
      // setHeaders: {
      //   Authorization:'Basic '+ btoa(environment.config.basicAuth.userName +':'+environment.config.basicAuth.password)
      // }
    })
    return next.handle(tokenizeReq);  
  }

  
}