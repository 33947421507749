<div class="aiContainer">
    <header>
        <h2>Ask to Zeeni</h2>
        <div class="pageTab">
            <ul>
                <li (click)="clickTabPage(1)" class="active">Content Creation</li>
                <li (click)="clickTabPage(2)" *ngIf="isHelpMeWrite">Help me write</li>
            </ul>
        </div>
    </header>

    <div class="workArea pad20">
    <div class="flex gap20 relative">
        <div class="bodyContainer pl20 pr20 pb20 pt0">
            <div class="tabs">
                <div class="tab active">Create Content</div>
                <div class="tab" (click)="clickTab(1)">Create Article</div>
            </div>
            <div class="relative pt20">
                <div class="contentLeftBlock">
                <ng-container class="tags" *ngIf="contentCreationDomainConfig?.isTags">
                    <div class="tagContainer">
                    <!-- <h6 class="pt20">Tags</h6> -->
                    <tags-creation (sendTags)="receivedTags($event)"></tags-creation>
                </div>
                   </ng-container>
                <div class="inputOutputContainer">
                   
                    <div class="outputContainer select" *ngIf="!!langList && langList.length>0">
                        <select  (change)="changeOutputLang($event)" [(ngModel)]="defaultOutputLang">
                            <option value ="" disabled="true">Choose Output language</option>
                            <option [value]="lang" *ngFor="let lang of langList">{{lang}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <h6 class="pt20">Enter your Text</h6><span class="wordCount pt20">Word limit <em>{{content?content.trim().split(' ').length:0}}</em>/{{wordLimit}}</span>
        </div>
                
                <div class="inputTextArea">
                   <div class="textInputContent" contenteditable="false">
                    <textarea   [(ngModel)]="content" [ngClass]="{filled: content}" placeholder="Enter Your Text..." (keyup)="trimContent(content)" >

                    </textarea>
                       <!-- <ckeditor [editor]="Editor" [(ngModel)]="content" name="body"></ckeditor> -->
                   </div>
                 
                 </div>
           
                   <div class="bottomOptions pt10">
                   <div class="select" *ngIf="!!toneList && toneList.length>0">
                   <select  (change)="changeTone($event)" [(ngModel)]="defaultTone">
                    <option value =""  disabled="true">Choose tone</option>
                    <option [value]="tone" *ngFor="let tone of toneList">{{tone}}</option>
                </select>
            </div>
            <div class="noofslidebix select" *ngIf="valueHeading=='Webstory'">
                <select  (change)="changeSlideNo($event)" [(ngModel)]="defaultSlideNo">
                    <option value ="0" disabled="true">Choose No of slides</option>
                    <option [value]="slide" *ngFor="let slide of noOfSlide">{{slide}}</option>
                </select>
            </div>

                   <!-- <div class="roboSuggestion">
                    <label [ngClass]="{hoverDisabled: !content || content?.trim().split(' ').length>wordLimit || content?.trim().length<1}"> <span> Create Content <img src="../../assets/up_down_arrow.svg" alt=""> </span>
                        <ul>
                            <li (click)="change(features.action)" *ngFor="let features of enableFeatures">{{features['label']}}</li>
                    </ul>
                    </label>
                    </div> -->
                </div>
                <p class="errorMsg" *ngIf="content?.trim().split(' ').length>wordLimit">Your word limit has been exceeded.</p>
                <div class="leftPanalBtnBlock pt10">
                    <div class="roboSuggestion">
                        <label [ngClass]="{hoverDisabled: !content || content?.trim().split(' ').length>wordLimit || content?.trim().length<1}"> <span> Create Content <img src="../../assets/up_down_arrow.svg" alt=""> </span>
                            <ul>
                                <li (click)="change(features.action)" *ngFor="let features of enableFeatures">
                                    <span  *ngIf="features.enable">{{features['label']}}</span>
                                </li>
                        </ul>
                        </label>
                        </div>
                </div>
        </div>
        <div class="SuggestionPart">
            <div class="animationLoader" *ngIf="!isAction && !valueHeading">
                    <img src="../../assets/image_3.svg" alt="" />
            </div>
            <div class="animationLoader" *ngIf="isAction">
                <span><img src="../../assets/rocket-loader.svg" height="262px"/></span>
                <!-- <div class="loader">
                    <span class="loader__element"></span>
                    <span class="loader__element"></span>
                    <span class="loader__element"></span>
                  </div> -->
            </div>
            <div  class="animationLoader" *ngIf="isRetry && !isAction">
          
                <a (click)='change(valueHeading)' class="btn_blue">Retry {{valueHeading}}</a>
           
            </div>
            <div *ngIf="valueHeading && !isAction" class="aiSuggestionArea">
            <h6 *ngIf="valueHeading" class="pt20">Content Created for {{valueHeading}}</h6>
            <h6 *ngIf="!valueHeading" class="pt20">Recommended by AI Assistant</h6>
            <div class="reacommendedArea">
            
            <ng-container *ngIf="!!bulletData && bulletData.length>0">
                <ul contenteditable="false" id="bullets">
                    <li *ngFor ="let bullets of bulletData;">
                        {{bullets}}</li>
                </ul>
            </ng-container>

            <ng-container *ngIf="!!seoData && seoData.length>0">
                <ul id="keywords" contenteditable="false" >
                    <li *ngFor="let keywords of seoData;">
                        {{keywords}}
                    </li>
                </ul>
            </ng-container>

            <ng-container *ngIf="!!faqsData && faqsData.length>0">
                <div id="faqs" contenteditable="false">
                <ng-container *ngFor="let faqs of faqsData;let i =index;">
                    <div class="Q_Ans">
                    <p>
                        <b><i>Q {{i+1}}: </i><span>{{faqs.question}}</span></b>
                    </p>
                    <p>                                   
                         Ans {{i+1}}: <span>{{faqs.answer}}</span>
                    </p>
                </div>
                </ng-container>
                </div>
            </ng-container>

            <ng-container *ngIf="!!expandedArticle">
                <div id="write_more">
                    <p contenteditable="false">
                   <b><i>Headline: </i></b> {{expandedArticle?.output?.headline}}
                    </p>
                    <p contenteditable="false">
                     <b><i>Summary: </i></b>{{expandedArticle?.output?.summary}}
                    </p>
                    <p contenteditable="false">
                    <b><i>Article: </i></b>{{expandedArticle?.output?.article}}
                    </p>
                    <!-- <p contenteditable="false">
                    <b><i>Prompt</i>: </b>{{expandedArticle?.output?.prompt}}
                    </p> -->
                </div>
            </ng-container>

            <ng-container *ngIf="!!qualitySuggestion && qualitySuggestion.length>0">
                <ng-container>
                    <ul contenteditable="false" id="qualityCheck">
                       <li *ngFor="let check of qualitySuggestion;">{{check}}</li>
                    </ul>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="!!suggestedHeadline && suggestedHeadline.length>0">
                <ng-container *ngFor="let headline of suggestedHeadline;let i=index;">
                    <p>
                        <label>
                            <input type="radio"  name="radio" id ="radio{{i+1}}" [(ngModel)]="selectedHeadline" value="{{headline}}">
                          <span>{{headline}}</span>
                        </label>
                      </p>
                </ng-container>
               
            </ng-container>
            <ng-container *ngIf="!!scoreData && scoreData.length>0">
                <ng-container *ngFor="let data of scoreData;">
                   

                    <ul id="scores">
                   <li>
                     
                        <span [ngClass]="data.flow>=90 ? 'green' : data.flow<90 && data.flow>=85 ? 'orange' : 'red'" role="progressbar" aria-valuenow="67" aria-valuemin="0" aria-valuemax="100" style="--value: {{data.flow}}"></span>
                        <em>FLOW</em>
                    </li>
                   <li>
                        
                        <span  [ngClass]="data.seo>=90 ? 'green' : data.seo<90 && data.seo >= 85 ? 'orange' : 'red'" role="progressbar" aria-valuenow="67" aria-valuemin="0" aria-valuemax="100" style="--value: {{data.seo}}"></span>
                            <em>SEO</em>
                        </li>
                   <li>
                    
                        <span [ngClass]="data.content>=90 ? 'green' : data.content<90 && data.content>=85 ? 'orange' : 'red'" role="progressbar" aria-valuenow="67" aria-valuemin="0" aria-valuemax="100" style="--value: {{data.content}}"></span>
                            <em>Content</em>
                        </li>
                   <li>
                       
                        <span  [ngClass]="data['relevant information']>=90 ? 'green' : data['relevant information']<90 && data['relevant information']>=85 ? 'orange' : 'red'" role="progressbar" aria-valuenow="67" aria-valuemin="0" aria-valuemax="100" style="--value: {{data['relevant information']}}"></span>
                            <em>Relavetn Information</em>
                        </li>
                   <li>
                       
                        <span  [ngClass]="data['viewer engagement']>=90 ? 'green' : data['viewer engagement']<90 && data['viewer engagement']>=85 ? 'orange' : 'red'" role="progressbar" aria-valuenow="67" aria-valuemin="0" aria-valuemax="100" style="--value: {{data['viewer engagement']}}"></span>
                            <em>Viewer Engagement</em>
                        </li>
                   <li>                    
                        <span  [ngClass]="data['combined score']>=90 ? 'green' : data['combined score']<90 && data['combined score']>=85 ? 'orange' : 'red'" role="progressbar" aria-valuenow="67" aria-valuemin="0" aria-valuemax="100" style="--value: {{data['combined score']}}"></span>
                            <em>Combined Score</em>
                        </li>
                    </ul>
                    
                </ng-container>
             
            </ng-container>

            <ng-container *ngIf="!!paraPhraseData">
                <p id="paraphrase" contenteditable="false">{{paraPhraseData}}</p>
            </ng-container>
            <ng-container *ngIf="!!suggestedSummary">
                <p id="summary" contenteditable="false">{{suggestedSummary}}</p>
            </ng-container>
            <ng-container *ngIf="!!translatedText">
                <p id="translated" contenteditable="false">{{translatedText}}</p>
            </ng-container>
            <ng-container *ngIf="!!webStory && webStory.length>0">
                <ul  contenteditable="false" class="webStoryUl" >
                    <li *ngFor="let data of webStory;let i = index;">
                        <label id="slide{{i}}">Slide{{i+1}}</label>
                        <div class="slideContent">
                        <p id="title{{i}}"><strong>Title:</strong>{{data.title}}
                             <span class="copySelction"><img src="../../assets/copy_icon.png" alt="Copy" (click)="copied(data.title)"/></span> 
                        </p>
                        <p id="content{{i}}"><strong>Content:</strong>{{data.content}}
                        <span class="copySelction"><img src="../../assets/copy_icon.png" alt="Copy" (click)="copied(data.content)"/></span> 
                        </p>
                    </div>
                    </li>
                </ul>
            </ng-container>
            </div>
            <div class="btn_block">
            <ng-container *ngIf="!!content && content?.trim().split(' ').length<=wordLimit">
            <a (click)='change(valueHeading)' class="btn_blue"  *ngIf="!isRetry">Recreate {{valueHeading}}</a>
            </ng-container>
            <a (click)='acceptData(valueHeading)' class="btn_blue" *ngIf="!isRetry && valueHeading !=='Webstory'">Copy</a>
        </div>
    </div>
        </div>  
    </div>
    </div>    
</div>


