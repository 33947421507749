<div class="loginBg">
  <div id="loader" *ngIf="isLogin"></div>
  <div class="loginBlock">
    <div class="loginSection">
      <div class="">
          <h4>Login with</h4>
          <div>
          <img class="ms-logo" src="../../../../assets/images/ms_logo.png" (click)="loginPopup()" alt="" />
      </div>
    </div>
  </div>
  <span style="padding: 10px;font-size: 28px;">OR</span>
    <div class="leftChannelsLogo">
      <div class="loginSectionArea">
        <div class="inputArea">
          <label>
            User Name
          </label>
          <div class="dataField">
            <input type="text" class="textBox" name="username" [(ngModel)]="username">
          </div>
        </div>
        <div class="inputArea">
          <label>
            Password
          </label>
          <div class="dataField">
            <input type="password" class="textBox" name="password" [(ngModel)]="password">
            <label class="validation" *ngIf="loginError != ''">
              {{loginError}}
            </label>
          </div>
        </div>
        
        <div class="inputArea btnBlock">
          <label>
            &nbsp;
          </label>
          <div class="dataField">
            <input type="submit" class="btnSubmit" (click)="temporaryLogin()"/>
            <input type="button" value="Reset" class="btnSubmit" (click)="reset()" >
  
          </div>
        </div>
      </div>
        </div>
        
        
    
  </div>
</div>