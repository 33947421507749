import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { env } from 'process';
import { HttpRequestService } from 'src/app/services/http-request.service';
import { NgxToastrService } from 'src/app/services/ngx-toastr.service';
import { environment } from 'src/environments/environment';
import { SearchTopicComponent } from '../search-topic/search-topic.component';

@Component({
  selector: 'app-daily-search-trend',
  templateUrl: './daily-search-trend.component.html',
  styleUrls: ['./daily-search-trend.component.scss']
})
export class DailySearchTrendComponent implements OnInit {
  dailyTrendingItems:any =[];
  dailyItemsDetails:any =[];
  isHelpMeWrite:boolean =false;
  selectedIndex: number | null = null;
  isTopicSearched:boolean =false;
  searchedTopic:string = '';
  @ViewChild('isSearchedPageCalled') isSearchedPageCalled!:SearchTopicComponent;
  constructor(private httpRequest: HttpRequestService, private toastr: NgxToastrService,
    private activatedRoute:ActivatedRoute, private router:Router) { }

  ngOnInit(): void {
    let domainName = this.activatedRoute.snapshot.queryParamMap.get('domainName');
    this.httpRequest.http_get(environment.config.configUrl +`/${domainName}.json`,undefined,undefined).subscribe((data:any) => {
      const response = data.body
      this.isHelpMeWrite = response['isHelpMeWrite']
    })
   this.getDailySearchTrends();
  }

  getDailySearchTrends():void{
    this.httpRequest.http_get(environment.config.helpMeWriteUrl +'/getDaily',undefined,undefined)
    .subscribe({
      next:(res:any)=>{
        this.dailyTrendingItems = res?.body?.item
        // let data = this.dailyTrendingItems;
        // this.dailyTrendingItems = data.filter((item:any) => item !== "No document");
      console.log('getDailySearchTrends res', this.dailyTrendingItems)
      this.selectItem(0);
      },
      error: (err)=>{console.log('getDailySearchTrends error',err)},
      complete: ()=>{console.log('getDailySearchTrends completed')},
    })
  }
  getDailyItemDetails(topic:string):void{
    
   this.httpRequest.http_post(environment.config.helpMeWriteUrl +'/getDailyTopic',{topic})
   .subscribe({
    next:(res:any)=>{
      console.log('getDailyItemDetails res', res)
      this.dailyItemsDetails =res?.body?.results;
      
    },
    error: (err)=>{console.log('getDailySearchTrends error',err)},
    complete: ()=>{console.log('getDailySearchTrends completed')},
   })
  }
  openRelatedArticle(relatedUrl:any):void{
    window.open(
      relatedUrl,'_blank'
    );
  }
  writeAboutTopic(topic:any):void{
    let domainName:any = this.activatedRoute.snapshot.queryParamMap.get('domainName');
    this.router.navigateByUrl("article-creation"+`?domainName=${domainName}&selectedText=${topic}`)
  }
  clickTab(tab:number){
    let route:any = '';
    if(tab==1){
      route = this.activatedRoute.snapshot.queryParamMap.get('domainName');
      this.router.navigateByUrl("realtime-trend"+`?domainName=${route}`)
  
    }
  }
  clickTabPage(tab:number){
    let route:any = '';
   
    if(tab == 1){
      route = this.activatedRoute.snapshot.queryParamMap.get('domainName');
      this.router.navigateByUrl("article-creation"+`?domainName=${route}`)
    } else {
      route = this.activatedRoute.snapshot.queryParamMap.get('domainName');
      this.router.navigateByUrl("help-me-write"+`?domainName=${route}`)
    }
  
  }
  selectItem(index: number): void {
    this.selectedIndex = index;
    const selectedItem = this.dailyTrendingItems[index];
    this.getDailyItemDetails(selectedItem?.topic);
  }
  searchTopic(topic:string):void{
  this.searchedTopic = topic;
  this.isTopicSearched = true;
    this.isSearchedPageCalled?.getSearchItemmDetails(this.searchedTopic)
  }
}
