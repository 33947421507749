import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpRequestService {

  constructor(
    private http: HttpClient
  ) { }

  http_get(url:string, headers?:HttpHeaders, params?:HttpParams) {
    return this.http.get(url,{headers, params, observe:'response'});
  }

  http_post(url:string, body: any, headers?:HttpHeaders, params?:HttpParams) {
    return this.http.post(url, body, {headers, params, observe:'response'});
  }

}
